
$dependencyManager.addDependencyCallback('gsap', () => {
  if (!window.navbar) {
    const NavbarFactory = require('./NavbarFactory');
    const customizeNavbarSection = require('./custom');
    
    new NavbarFactory();
  
    var navbarSection = jQuery('.mad-navbar__section');
    if (navbarSection.length > 0) {
      customizeNavbarSection(navbarSection)
    }
  }
});

jQuery(document).ready(() => {
  adjustNavbarMargin();

  // Call the function on window resize
  jQuery(window).resize(adjustNavbarMargin);
});

function adjustNavbarMargin() {
  // Check for the presence of the WordPress admin bar
  const adminBar = jQuery('#wpadminbar');
  if (adminBar.length > 0) {
    const adminBarHeight = adminBar.outerHeight();
    jQuery('.mad-navbar__section').css('margin-top', adminBarHeight);
    jQuery('.mad-mobile-navmenu__container').css({
      'margin-top': adminBarHeight,
      'height': `calc(100vh - ${adminBarHeight}px)`
    });
  } else {
    // If no admin bar, reset the margin to 0
    jQuery('.mad-navbar__section').css('margin-top', 0);
    jQuery('.mad-mobile-navmenu__container').css({
      'margin-top': 0,
      'height': `calc(100vh)`
    });
  }
};