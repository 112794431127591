class MobileNavMenu {
  constructor() {
    this.$container = jQuery('.mad-mobile-navmenu__container');
    this.$navMenu = jQuery('.mad-mobile-navmenu');
    this.$navItems = jQuery('.mad-mobile-navmenu__item');
    this.$subItems = jQuery('.mad-mobile-navmenu__subitems');
    this.$closeButton = jQuery('.mad-mobile-navmenu__close');
    this.$itemToggles = jQuery('.mad-mobile-navmenu__item-toggle');

    this.init();
  }

  init() {
    this.$container.appendTo(document.body);
    gsap.set(this.$container, {
      x: '-100%',
      display: 'none'
    });

    // Open menu event
    jQuery('.mad-navbar__hamburger-button').on('click', (e) => {
      e.preventDefault();
      this.openNavMenu();
    });

    // Close menu event
    this.$closeButton.on('click', (e) => {
      e.preventDefault();
      this.closeNavMenu();
    });

    // Toggle subitems event
    this.$itemToggles.on('click', (e) => {
      e.preventDefault();
      const $toggle = jQuery(e.currentTarget);
      const $wrapper = $toggle.closest('.mad-mobile-navmenu__item-wrapper');

      if ($wrapper.hasClass('active')) {
        this.closeSubitems($wrapper);
      } else {
        this.openSubitems($wrapper);
      }
    });
  }

  openNavMenu() {
    jQuery('body').addClass('no-scroll');
    gsap.set(this.$container, {
      display: 'flex'
    });

    gsap.fromTo(this.$container, {
      x: '-100%'
    }, {
      x: 0,
      duration: 0.5,
      ease: 'power2.inOut'
    });
  }

  closeNavMenu() {
    jQuery('body').removeClass('no-scroll');
    gsap.to(this.$container, {
      x: '-100%',
      duration: 0.5,
      ease: 'power2.inOut',
      onComplete: () => {
        gsap.set(this.$container, {
          display: 'none'
        });
      }
    });
  }

  openSubitems($wrapper) {
    // Close any open subitems
    this.$navItems.each((_, item) => {
      const $itemWrapper = jQuery(item).closest('.mad-mobile-navmenu__item-wrapper');
      if ($itemWrapper.hasClass('active')) {
        this.closeSubitems($itemWrapper);
      }
    });

    // Open current subitems
    const $subitemsWrapper = $wrapper.find('.mad-mobile-navmenu__subitems-wrapper');
    const $toggleIcon = $wrapper.find('.mad-mobile-navmenu__item-toggle-icon');

    $wrapper.addClass('active');
    $toggleIcon.addClass('rotated');

    gsap.to($subitemsWrapper, {
      height: 'auto',
      duration: 0.3,
      ease: 'power2.inOut',
      onStart: () => {
        gsap.set($subitemsWrapper, {
          height: $subitemsWrapper.prop('scrollHeight')
        });
      }
    });
  }

  closeSubitems($wrapper) {
    const $subitemsWrapper = $wrapper.find('.mad-mobile-navmenu__subitems-wrapper');
    const $toggleIcon = $wrapper.find('.mad-mobile-navmenu__item-toggle-icon');

    $wrapper.removeClass('active');
    $toggleIcon.removeClass('rotated');

    gsap.to($subitemsWrapper, {
      height: 0,
      duration: 0.3,
      ease: 'power2.inOut'
    });
  }
}

module.exports = MobileNavMenu;
