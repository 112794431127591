const Navbar = require('../Navbar')
const NavbarItemDefault = require('./NavbarItemDefault')

class NavbarDefault extends Navbar {
  constructor(container) {
    super(container);
    this.$megaRow = jQuery(container).find('.mad-navbar__row--mega');
    this.NavbarItemClass = NavbarItemDefault;
    this.init();
  }

  initNavItems() {
    super.initNavItems();
    for (const navItem of this.navItems) {
      const navItemContainer = navItem.$container

      jQuery(navItemContainer).on('mouseenter', (event) => {
        if (navItem.$submenu.length > 0) {
          this.hideAllNavItems(navItem);

          navItem.show(function () {
            this.setMegaRow(navItem);
          }.bind(this));
        } else {
          this.hideAllNavItems();
          this.resetMegaRow();
        }
      });

      jQuery(navItem.$icon).on('click', (event) => {
        event.preventDefault();
        this.hideAllNavItems(navItem);

        if (!navItem.isActive) {
          navItem.show(function () {
            this.setMegaRow(navItem);
          }.bind(this));
        } else {
          this.resetMegaRow();
        }
      });
    }
  }

  initLogo() {
    jQuery(this.$container).find('.mad-navbar__logo-container > a').on('mouseenter', () => {
      this.hideAllNavItems();
      this.resetMegaRow();
    })
  }

  initContainer() {
    this.$parentContainer.on('mouseleave', () => {
      this.resetTimeout = setTimeout(() => {
        this.resetMegaRow();
      }, 150);
    });

    this.$parentContainer.on('mouseenter', () => {
      if (this.resetTimeout) {
        clearTimeout(this.resetTimeout);
        this.resetTimeout = null;
      }
    });
  }
  
  setMegaRow(navbarItem) {
    gsap.to(this.$megaRow, {
      height: navbarItem.getHeight(),
      duration: 0.3,
      ease: 'power2.inOut'
    });
  }

  resetMegaRow() {
    this.hideAllNavItems();
    gsap.to(this.$megaRow, {
      height: 0,
      duration: 0.3,
      ease: 'power2.inOut'
    });
  }
}

module.exports = NavbarDefault;