const Navbar = require('../Navbar');
const NavbarItemDetailed = require('./NavbarItemDetailed');

class NavbarDetailed extends Navbar {
  constructor(container) {
    super(container);
    this.$megaRow = jQuery(container).find('.mad-navbar__row--mega');
    this.$pageInfoContainer = jQuery(container).find('.mad-navbar-page-info__container'); // Reference to page info container
    this.NavbarItemClass = NavbarItemDetailed;
    this.init();
  }

  initNavItems() {
    super.initNavItems();
    for (const navItem of this.navItems) {
      navItem.hideAllNavItems = this.hideAllNavItems.bind(this);
      navItem.setMegaRow = this.setMegaRow.bind(this);
  
      if (navItem.hasSubmenu) {
        navItem.initSubmenuTitle();
        this.initNavItemSubmenuItems(navItem);
      }
    }
  
    this.hoverTimeout = null; // Declare hoverTimeout as a member variable
  
    for (const navItem of this.navItems) {
      const navItemContainer = navItem.$container;
  
      jQuery(navItemContainer).on('mouseenter', (event) => {
        // Set a timeout to only trigger the hover effect after 200ms
        this.hoverTimeout = setTimeout(() => {
          if (navItem.$submenu.length > 0) {
            this.hideAllNavItems(navItem, true);
  
            navItem.show(function () {
              this.setMegaRow(navItem);
            }.bind(this));
          } else {
            this.hideAllNavItems();
            this.resetMegaRow();
          }
        }, 100); // 200ms delay
      });
  
      // Clear the timeout if the mouse leaves before the delay finishes
      jQuery(navItemContainer).on('mouseleave', () => {
        clearTimeout(this.hoverTimeout); // Cancel the hover action if mouse leaves early
      });
    }
  }
  
  

  initNavItemSubmenuItems(navItem) {
    for (const $submenuNavItem of jQuery(navItem.$submenu).find('.mad-navbar__submenu .mad-navbar__submenu-item')) {
      const submenuNavItem = new NavbarItemDetailed(jQuery($submenuNavItem))
      submenuNavItem.hasParent = true;
      submenuNavItem.hideAllNavItems = this.hideAllNavItems.bind(this);
      submenuNavItem.setMegaRow = this.setMegaRow.bind(this);

      navItem.$submenuNavItems.push(submenuNavItem);
      submenuNavItem.initSubmenuEvents();
      if (submenuNavItem.hasSubmenu) {
        this.initNavItemSubmenuItems(submenuNavItem);
      }
    }
  }

  setMegaRow() {
    // Calculate the maximum height of the children
    let megaWrapper = this.$megaRow.find('.mad-navbar__mega-wrapper');

    let submenus = Array.from(this.$megaRow.find('.mad-navbar__submenu-level .mad-navbar__submenu-wrapper.active .mad-navbar__submenu'));
    let pageInfoContainer = this.$megaRow.find('.mad-navbar-page-info__container');
    let $submenuLevel = this.$megaRow.find('.mad-navbar__submenu-level').first();
    let padding = $submenuLevel.innerHeight() - $submenuLevel.height();
    let maxHeight = Math.max(...submenus.concat([pageInfoContainer]).map(child => jQuery(child).outerHeight()));

    // Apply the GSAP animation to the maximum height
    gsap.to(this.$megaRow, {
      height: megaWrapper.outerHeight(),
      duration: 0.3,
      ease: 'power2.inOut'
    });
  }

  resetMegaRow() {
    this.hideAllNavItems();
    gsap.to(this.$megaRow, {
      height: 0,
      duration: 0.3,
      ease: 'power2.inOut'
    });
  }

  hideAllNavItems(exception = null, hideFirstLevel=false) {
    for (const navItem of this.navItems) {
      if (navItem !== exception) {
        navItem.hide(exception, hideFirstLevel);
      }
    }
  }

  initContainer() {
    this.$parentContainer.on('mouseleave', () => {
      this.resetTimeout = setTimeout(() => {
        this.resetMegaRow();
      }, 150);
    });

    this.$parentContainer.on('mouseenter', () => {
      if (this.resetTimeout) {
        clearTimeout(this.resetTimeout);
        this.resetTimeout = null;
      }
    });
  }
}

module.exports = NavbarDetailed;
