const NavbarItem = require('../NavbarItem')

class NavbarItemDefault extends NavbarItem {
  constructor(container) {
    super(container)
  }

  show(activationCallback) {
    super.show(activationCallback)

    gsap.set(this.$submenu, {
      display: 'grid',
      opacity: 1,
      onComplete: () => {
        activationCallback();

        gsap.to(this.$icon, {
          rotation: -90,
          duration: 0.2,
          ease: 'power2.inOut'
        });
      }
    });
  }

  hide(animated = false) {
    super.hide()

    if (!animated) {
      gsap.set(this.$submenu, {
        display: 'none'
      });
    } else {
      gsap.to(this.$submenu, {
        opacity: 0,
        duration: 0.3,
        onComplete: () => {
          if (this.isActive) return;
          gsap.set(this.$submenu, {
            display: 'none',
            opacity: 1
          });
        }
      });
    }
  }
}

module.exports = NavbarItemDefault