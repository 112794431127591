const NavbarItem = require('./NavbarItem');
const MobileNavMenu = require('./mobileNavmenu/MobileNavMenu');

class Navbar {
  constructor(container) {
    this.$container = jQuery(container);
    this.$parentContainer = this.$container.closest('.brz-row');
    this.navItems = [];
    this.resetTimeout = null;
    this.NavbarItemClass = NavbarItem
  }

  init() {
    this.initNavItems();

    this.initLogo();

    this.initContainer();

    this.initMobileNavMenu();

    const initialHash = window.location.hash;
    if (initialHash) {
      this.setActiveNavItem(initialHash);
    }
  }

  initNavItems() {
    for (const navItemContainer of jQuery(this.$container).find('.mad-navbar__nav .mad-navbar__nav-item[data-menu-id]')) {
      const navItem = new this.NavbarItemClass(jQuery(navItemContainer));
      this.navItems.push(navItem);

      jQuery(navItemContainer).find('>a[href^="#"]').click((event) => {
    const element = jQuery(event.target);
    const link = element.closest('a');
    const target = link[0].hash;
    this.setActiveNavItem(target);
});

    }
  }

  initLogo() {
    jQuery(this.$container).find('.mad-navbar__logo-container > a').on('mouseenter', () => {
      this.hideAllNavItems();
    })
  }

  initContainer() {

  }

  initMobileNavMenu() {
    this.mobileNavMenu = new MobileNavMenu();
  }

  hideAllNavItems(exception = null) {
    for (const navItem of this.navItems) {
      if (navItem !== exception && navItem.isActive) {
        navItem.hide();
      }
    }
  }

  // Function to set the active class on the nav item
  setActiveNavItem(target) {
    // Remove active class from all nav items
    jQuery('.mad-navbar__nav a').removeClass('active');

    // Add active class to the clicked nav item
    jQuery(`.mad-navbar__nav a[href="${target}"]`).addClass('active');
  }
}

module.exports = Navbar