const Navbar = require('./Navbar');
const NavbarClassic = require('./classicVariant');
const NavbarDefault = require('./defaultVariant');
const NavbarDetailed = require('./detailedVariant/NavbarDetailed');

class NavbarFactory {
  constructor() {
    var navbarContainer = jQuery('.mad-navbar__container');
    if (navbarContainer.length == 0) return;

    navbarContainer = navbarContainer.first();

    if (navbarContainer.hasClass('mad-navbar__container--classic')) {
      console.log('Initializing classic navbar.');
      window.navbar = new NavbarClassic(navbarContainer);
    } else if (navbarContainer.hasClass('mad-navbar__container--detailed')) {
      console.log('Initializing detailed navbar.');
      window.navbar = new NavbarDetailed(navbarContainer);
    } else {
      console.log('Initializing default navbar.');
      window.navbar = new NavbarDefault(navbarContainer);
    }
  }
}

module.exports = NavbarFactory;
