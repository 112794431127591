const NavbarItem = require('../NavbarItem');

class NavbarItemDetailed extends NavbarItem {
  constructor(container) {
    super(container);
    this.hasParent = false;
    this.$submenuNavItems = [];
    this.$submenuTitle = '';
    this.$pageInfo = jQuery(`.mad-navbar-page-info[data-page-info-id="${this.menuId}"]`); // Reference to page info
    if (this.hasSubmenu) {
      this.$submenuTitle = this.$submenu.find('.mad-navbar__submenu-title').first()
    }
    this.hideAllNavItems = null;
    this.setMegaRow = null;
  }

  show(activationCallback) {
    super.show(activationCallback);
    if (this.hasSubmenu) this.$submenu.show();
    this.showPageInfo();
    activationCallback();
  }

  hide(exception = null, hideFirstLevel = false) {
    const isException = this == exception;
    const childIsException = this.childIsException(exception);
    var result = !isException && !childIsException;
    if (!isException && !childIsException) {
      if (this.hasParent || hideFirstLevel) this.$submenu.hide();
      super.hide(exception);
    }
    for (const submenuNavItem of this.$submenuNavItems) {
      submenuNavItem.hide(exception);
    }
    if (!isException && !childIsException) {
      this.hidePageInfo();
    }
  }

  childIsException(exception) {
    for (const childNavItem of this.$submenuNavItems) {
      if (childNavItem == exception || childNavItem.childIsException(exception)) return true;
    }
    return false;
  }

  initSubmenuEvents() {
    jQuery(this.$container).on('mouseenter', (event) => {
      this.hideAllNavItems(this);
      this.deactivateSubmenuNavItems();
      this.activate();
      if (this.hasSubmenu) {
        this.$submenu.show();
      }
      this.showPageInfo();
    });
    this.initSubmenuTitle();
  }

  initSubmenuTitle() {
    jQuery(this.$submenuTitle).on('mouseenter', (event) => {
      this.hideSubmenuNavItems();
      this.showPageInfo();
    })
  }

  deactivateSubmenuNavItems() {
    for (const submenuNavItem of this.$submenuNavItems) {
      submenuNavItem.deactivate();
    }
  }

  hideSubmenuNavItems() {
    for (const submenuNavItem of this.$submenuNavItems) {
      submenuNavItem.hide();
    }
  }

  showPageInfo() {
    // Hide all other page infos first
    jQuery('.mad-navbar-page-info').hide();
    // Show this item's page info
    this.$pageInfo.show();
    this.setMegaRow();
  }

  hidePageInfo() {
    // Hide this item's page info
    this.$pageInfo.hide();
  }
}

module.exports = NavbarItemDetailed;
