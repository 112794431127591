const NavbarItem = require('../NavbarItem')

class NavbarItemClassic extends NavbarItem {
  constructor(container) {
    super(container);
  }
  
  show(activationCallback) {
    if (this.isActive) return;
    super.show(activationCallback)

    if (this.hasSubmenu) {
      gsap.fromTo(this.$submenu, {
        y: 20,
        opacity: 0,
        pointerEvents: 'none',
      }, {
        y: 0,
        opacity: 1,
        pointerEvents: 'all',
        duration: 0.2,
        ease: 'power2.inOut'
      })
    }
  }

  hide() {
    if (!this.isActive) return;
    super.hide()

    if (this.hasSubmenu) {
      if (this.hasSubmenu) {
        gsap.fromTo(this.$submenu, {
          opacity: 1,
          pointerEvents: 'all',
        }, {
          opacity: 0,
          pointerEvents: 'none',
          duration: 0.3
        })
      }
    }
  }
}

module.exports = NavbarItemClassic