const Navbar = require('../Navbar');
const NavbarItemClassic = require('./NavbarItemClassic');

class NavbarClassic extends Navbar {
  constructor(container) {
    super(container);
    this.NavbarItemClass = NavbarItemClassic

    this.init();
  }

  initNavItems() {
    super.initNavItems();
    for (const navItem of this.navItems) {
      const navItemLink = navItem.$link

      jQuery(navItemLink).on('mouseenter', (event) => {
        if (navItem.$submenu.length > 0) {
          this.hideAllNavItems(navItem);

          navItem.show();
        } else {
          this.hideAllNavItems();
        }
      });

      jQuery(navItem.$icon).on('click', (event) => {
        event.preventDefault();
        this.hideAllNavItems(navItem);

        if (!navItem.isActive) {
          navItem.show();
        } else {
          navItem.hide();
        }
      });
    }
  }

  initContainer() {
    this.$parentContainer.on('mouseleave', () => {
      console.log('mouseleave container')
      this.resetTimeout = setTimeout(() => {
        console.log('hide all after mouseleave container')
        this.hideAllNavItems();
      }, 150);
    });

    this.$parentContainer.on('mouseenter', () => {
      if (this.resetTimeout) {
        clearTimeout(this.resetTimeout);
        this.resetTimeout = null;
      }
    });
  }
}

module.exports = NavbarClassic;