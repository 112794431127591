class NavbarItem {
  constructor(container) {
    this.$container = jQuery(container);
    this.$link = this.$container.find('a.mad-navbar__nav-item-link');
    this.menuId = this.$container.attr('data-menu-id');
    this.hasSubmenu = false;
    this.$icon = null;
    this.$submenu = jQuery(`.mad-navbar__submenu-wrapper[data-parent-menu-id="${this.menuId}"] .mad-navbar__submenu-container`);
    this.jsEvent = this.$link.attr('data-click-event');
    
    if (this.$submenu.length > 0) {
      this.hasSubmenu = true;
      this.$icon = jQuery(this.$container.find('.mad-navbar__nav-item-icon'));
      this.$wrapper = this.$submenu.closest('.mad-navbar__submenu-wrapper');
    }

    this.isActive = false;

    if (this.jsEvent) {
      this.$link.on('click', (e) => {
        // e.preventDefault(); 
        this.triggerJsEvent();
      });
    }
  }

  show(activationCallback) {
    if (this.$submenu.length == 0) {
      activationCallback();
      return;
    }
    
    this.activate();

    gsap.to(this.$icon, {
      rotation: 0,
      duration: 0.18,
      ease: 'power2.inOut'
    });
  }

  hide() {
    this.deactivate();
    if (this.$submenu.length == 0) return;

    
    gsap.to(this.$icon, {
      rotation: 0,
      duration: 0.18,
      ease: 'power2.inOut'
    });
  }

  activate() {
    this.isActive = true;
    this.$container.addClass('active');
    this.$wrapper?.addClass('active');
  }

  deactivate() {
    this.isActive = false;
    this.$container.removeClass('active');
    this.$wrapper?.removeClass('active');
  }

  getHeight() {
    return this.$submenu.outerHeight();
  }

  triggerJsEvent() {
    if (this.jsEvent) {
      jQuery('body').trigger(this.jsEvent);
    }
  }
}

module.exports = NavbarItem;